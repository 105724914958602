
const TemporaryRegistration = () => {
    return (
        <>
            <section className='gray-banner'>
                <div className="container mt-4">
                    <div className="card shadow border-0">
                        <div className="card-body">
                            <div className="w-100">
                                <h1 className='fs-22 fw-700'>New Registration</h1>
                                <hr />
                            </div>
                            <div className="px-3">

                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="w-100 text-end">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default TemporaryRegistration;