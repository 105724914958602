
const Userfooter = () => {
    return (
    <>
        <footer className="bg-dark">

        </footer>
    </>
    )
}
export default Userfooter;
  